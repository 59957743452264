import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'
import Img from "gatsby-image"

import Settings from 'src/styles/settings'

import { Media, DropShadow } from 'src/styles/mixins'

const Container = styled.div`

  ${Media.desktop`
    margin-bottom: 4rem;
  `}

  ${Media.mobile`
    margin-bottom: 2rem;
  `}
`;

const Inner = styled.div`
  position: relative;
  max-width: 1068px;
  margin: 0 auto;
`;

const LatestLink = styled(Link)`
  text-decoration: none;

  ${Media.desktop`
    color: ${Settings.colors.baseColor};
  `}

  ${Media.mobile`
    color: ${Settings.colors.textColor};
  `}
`;

const Thumbnail = styled.div`

  ${Media.mobile`
    margin-bottom: .5rem;
  `}
`;

const Image = styled(Img)`
  width: 100%;

  ${Media.desktop`
    border-radius: .5rem;
  `}
`;

const Content = styled.div`

  ${LatestLink}:hover & {
    ${DropShadow};
  }

  ${Media.desktop`
    position: absolute;
    right: -1rem;
    bottom: -1rem;
    box-sizing: border-box;
    border-radius: .5rem;
    width: 50%;
    padding: 2rem 4rem;
    background-color: ${Settings.colors.keyColor};
    transition: box-shadow ${Settings.sizes.sec} ease;
  `}

  ${Media.mobile`
    padding: 0 1rem;
  `}
`;

const Title = styled.h1`
  margin: 0;

  ${Media.desktop`
    margin-bottom: 1rem;
    font-size: 2rem;
  `}

  ${Media.mobile`
    margin-bottom: .5rem;
    font-size: 1.14rem;
  `}
`;

const Description = styled.div`
  margin-bottom: .5rem;
`;

const Date = styled.div`
  text-align: right;
  font-size: .875rem;
`;

const Latest = ({ latest }) => (
  <Container>
    <Inner>
      <LatestLink to={`/${latest.slug}`} title={ latest.title }>
        <Thumbnail>
          <Image fluid={ latest.image.fluid } alt={ latest.title } />
        </Thumbnail>
        <Content>
          <Title dangerouslySetInnerHTML={{ __html: latest.title }} />
          <Description dangerouslySetInnerHTML={{ __html: latest.excerpt.childMarkdownRemark.html }} />
          <Date dangerouslySetInnerHTML={{ __html: latest.date }} />
        </Content>
      </LatestLink>
    </Inner>
  </Container>
)

Latest.propTypes = {
  latest: PropTypes.object,
}

export default Latest
