import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from "gatsby-image"

import Settings from 'src/styles/settings'

import { Media } from 'src/styles/mixins'

const Container = styled.nav`
  margin-bottom: 2rem;

  ${Media.mobile`
    overflow: auto;
  `}
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  ${Media.desktop`
    justify-content: center;
  `}
`;

const Item = styled.li`
  text-align: center;

  ${Media.desktop`
    margin: 0 1rem;
  `}

  ${Media.mobile`
    margin: 0 .5rem;
  `}
`;

const ItemLink = styled(Link)`
  color: ${Settings.colors.keyColor};
  text-decoration: none;
`;

const Thumbnail = styled.div`
  display: block;
  position: relative;
  margin-bottom: .5rem;
  border: 4px solid ${Settings.colors.keyColor};
  border-radius: 100px;
  overflow: hidden;

  ${Media.desktop`
    width: 60px;
    height: 60px;
  `}

  ${Media.mobile`
    width: 40px;
    height: 40px;
  `}
`;

const Image = styled(Img)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Title = styled.h1`
  font-size: .875rem;
`;

const Animal = ({ animals }) => (
  <Container>
    <List>
      { animals && animals.map(({ node: animal }) => (
        <Item key={ animal.id }>
          <ItemLink to={`/tag/${ animal.slug }`} title={ animal.title }>
            <Thumbnail><Image fluid={ animal.icon.fluid } alt={ animal.title } /></Thumbnail>
            <Title dangerouslySetInnerHTML={{ __html: animal.title }} />
          </ItemLink>
        </Item>
      ))}
    </List>
  </Container>
)

Animal.propTypes = {
  animals: PropTypes.array,
}

export default Animal
