import React from 'react'
import { graphql } from 'gatsby'

import styled, { css } from 'styled-components'
import { Media } from 'src/styles/mixins'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Latest from 'src/components/molecules/Latest'
import Animal from 'src/components/molecules/Animal'
import Wrapper from 'src/components/organisms/Wrapper'
import Archive from 'src/components/molecules/Archive'

const IndexPage = ({ data }) => {
  const latestPost = data.latestPost.edges[0].node
  const posts = data.allContentfulPosts.edges
  const settings = data.contentfulSettings
  const animals = data.allContentfulTags.edges

  return (
    <Layout>
      <SEO />

      <Latest latest={ latestPost } />

      <Animal
        animals={ animals } />

      <Wrapper settings={ settings }>
        <Archive type="main" posts={ posts } />
      </Wrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    latestPost: allContentfulPosts(
      limit: 1,
      filter: {node_locale: {eq: "ja-JP"}},
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          id
          title
          image {
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid
            }
          }
          excerpt {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          slug
          tags {
            id
            group
            title
          }
          date(formatString: "YYYY.MM.DD")
          createdAt(formatString: "YYYY.MM.DD")
          updatedAt(formatString: "YYYY.MM.DD")
        }
      }
    }

    allContentfulPosts(
      skip: 1,
      filter: {node_locale: {eq: "ja-JP"}},
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          id
          title
          image {
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid
            }
          }
          excerpt {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          slug
          tags {
            id
            group
            title
          }
          date(formatString: "YYYY.MM.DD")
          createdAt(formatString: "YYYY.MM.DD")
          updatedAt(formatString: "YYYY.MM.DD")
        }
      }
    }

    allContentfulTags(
      filter: {
        group: { in: 2 },
        node_locale: { eq: "ja-JP" }
      },
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          icon {
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    contentfulSettings(node_locale: {eq: "ja-JP"}) {
      siteName
      siteCaption
      siteDescription
      twitter
      facebook
      instagram
      line
    }
  }
`